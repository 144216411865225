import * as Sentry from '@sentry/gatsby';

const environment = process.env.GATSBY_ENVIRONMENT || process.env.NODE_ENV || 'unknown';

declare const window: any;
declare const CS_CONF: any;
declare const _uxa: any;

Sentry.init({
  dsn: 'https://670da06a728d43e2bc1c9bb104e905d3@o256546.ingest.sentry.io/5423928',
  environment: environment,
  enabled: ['production', 'kaos'].includes(environment),
  denyUrls: ['ads-engagement.presage.io'],
  release: ['production', 'kaos'].includes(environment) ? process.env.BUILDKITE_COMMIT : 'dev',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event, hint) {
    /*
    *Name: Sentry / Contentsquare Integration
    *Version: 1.0.0
    */
    (function () {
        const eventPayload = event;

        function csCallback() {
            if (!disableCallback) {
                disableCallback = true;

                if (window.CS_CONF && CS_CONF.integrations_handler && CS_CONF.integrations_handler.sentry) {
                    CS_CONF.integrations_handler.sentry(eventPayload);
                }
            }
        }
        let disableCallback = false;
        window._uxa = window._uxa || [];
        _uxa.push(["afterPageView", csCallback]);
    })();
    //End Contentsquare Integration
    return event;
  },
});